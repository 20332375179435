import React from 'react';
import Title from './Title';
import AboutLogo from '../images/Nagend.jpg';
import {ProductConsumer} from '../Context/Context';

export default function About() {
    return (
      <ProductConsumer>
          {value=>{

            const {setClass}=value;  
              return(
<section className="about">
<div className="container">
<Title title="About" color={setClass?"#fefefe":"grey"} />
<div className="about-center">
    <div className="about-logo">
        <img src={AboutLogo} alt="logo"/>
    </div>

    <div className={setClass?"about-texts dark-mode-white":"about-texts"}>
        <div className={setClass?"dark-mode-orange":"profile"}>
            <h2>CEO</h2>

        </div>
        <div className="name">
            <p><span className={setClass?"dark-mode-orange":" "}>Name -  </span>Nagendra Baithwar</p>
        </div>

        <div className="age">
            <p><span className={setClass?"dark-mode-orange":" "} >Age -  </span>23</p>
        </div>

        <div className="adress">
            <p><span className={setClass?"dark-mode-orange":" "}>Address -  </span>Raipur</p>
        </div>

        <div className="email">
            <p><span className={setClass?"dark-mode-orange":" "}>Email -  </span><a href="nagendrabaithwar927@gmail.com" className={setClass?"dark-mode-mail":" "}>nagendrabaithwar927@gmail.com</a></p>
        </div>
        
        <div className="phone">
            <p><span className={setClass?"dark-mode-orange":" "}>Phone -  </span>+(91) 8770038486</p>
        </div>

        <div className="website">
            <p><span className={setClass?"dark-mode-orange":" "}>Website =  </span>www.bwarr.com</p>
        </div>

            <div className={setClass?"about-media dark-mode-orangeBack":"about-media"}>
                {/* <a href="https://www.youtube.com/channel/UCLSCq6wNL8QHrAl9XHEm3YQ?view_as=subscriber" target="_blank" rel="noopener noreeferrer" ><i className="fab fa-youtube-square"></i></a> */}

                <a href="https://www.instagram.com/ceo_mr.nagendra_baithwar/" target="_blank" rel="noopener noreeferrer" ><i className="fab fa-instagram"></i></a>

                <a href="https://www.facebook.com/nagendra.baithwar.7" target="_blank" rel="noopener noreeferrer" ><i className="fab fa-facebook-square"></i></a>

                {/* <a href="https://twitter.com/NileshShriwas3" target="_blank" rel="noopener noreeferrer" ><i className="fab fa-twitter"></i></a> */}
            </div>
        
    </div>
</div>


</div>
</section>
              )
          }}
      </ProductConsumer>
    )
}

import React from 'react';
import { Card, Content, Icon, PlanTitle, PlanCost, ActionButton, BackgroundSquare } from './PriceCard.CSS'
import "../assets/icofont.min.css";

const PriceCard = () => {
   
    return (
        <div>
            <Card>
            <BackgroundSquare />
                <Content>
                    <Icon className="icofont-paper-plane" />
                    <PlanTitle>Homes</PlanTitle>
                    <PlanCost>Clients <br /> (200+)</PlanCost>
                    <i className="icofont-check" />
                    <spam>No Brokrege Charges</spam>
                    <i className="icofont-check" />
                    <spam>Best Seller in Raipur</spam>
                    <i className="icofont-check" />
                    <spam>Customer Satisfactions</spam>
                    
                    <ActionButton>Selling</ActionButton>
                </Content>
            </Card>
            <br />
            <br/>
            <Card>
            <BackgroundSquare />
                <Content>
                    <Icon className="icofont-paper-plane" />
                    <PlanTitle>Ploats</PlanTitle>
                    <PlanCost>Clients <br /> (50+)</PlanCost>
                    <spam>No Brokrege Charges</spam>
                    <i className="icofont-check" />
                    <spam>Best Seller in Raipur</spam>
                    <i className="icofont-check" />
                    <spam>Customer Satisfactions</spam>
                    <ActionButton>Selling</ActionButton>
                </Content>
            </Card>
            <br />
            <br />
            <Card>
            <BackgroundSquare />
                <Content>
                    <Icon className="icofont-paper-plane" />
                    <PlanTitle>Flats</PlanTitle>
                    <PlanCost>Clients <br /> (150+)</PlanCost>
                    <spam>No Brokrege Charges</spam>
                    <i className="icofont-check" />
                    <spam>Best Seller in Raipur</spam>
                    <i className="icofont-check" />
                    <spam>Customer Satisfactions</spam>
                    <ActionButton>Selling</ActionButton>
                </Content>
            </Card>
        </div>
    )
}

export default PriceCard

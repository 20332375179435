import React from 'react';
import './card-style.css';

const CardUI = (props) => {
    return (
        <div className="card text-center">
            <div className="overflow">
                <img src={props.imgsrc} alt="Image" className="card-img-top" />
            </div>
            <div className="card-body text-dark">
                <h4 className="card-title">{props.title}</h4>
                <p className="card-text text-secondary">
                    1-BHK, 2-BHK, 3-BHK & Propertys 
                </p>
            </div>
        </div>
    )
}

export default CardUI;

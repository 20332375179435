import React from 'react'
import Bounce from 'react-reveal/Bounce';

function Titles() {
    return (
        <div style={{textAlign: "center", fontFamily: 'Franklin Gothic Medium'}}>
                 <Bounce right delay={500} duration={2000} cascade>
                 <h3 style={{color: '#ffffff'}} >BWARR BUILDCON</h3>
                 </Bounce>
                  <h2 style={{color: '#e9e4f0'}}>&</h2>
                 <Bounce left delay={2000} duration={2000} cascade>
                 <h3 style={{color: '#ffffff'}}>PROPERTY SOLUTIONS</h3>
                 </Bounce>
        </div>
    )
}

                    






export default Titles

import React, { Component } from 'react';
import CardUI from './CardUI';

import img1 from '../Areas/home1.jpg';
import img2 from '../Areas/home2.jpg';
import img3 from '../Areas/home3.jpg';
import img4 from '../Areas/home4.jpg';
import img5 from '../Areas/home5.jpg';
import img6 from '../Areas/home6.jpg';
import img7 from '../Areas/home7.jpg';
import img8 from '../Areas/home8.jpg';
import img9 from '../Areas/home9.jpg';
import img10 from '../Areas/home10.jpg';
import img11 from '../Areas/home11.jpg';
import img12 from '../Areas/home12.jpg';
import Titles from '../Titles/Titles';
import ImageSliders from '../Sliders/ImageSliders';

class Cards extends Component {
    render() {
        return (
            <>
            <Titles />
            <ImageSliders />
            <div className='container-fluid d-flex justify-content-center'>
                <div className='row'>
                    <div className='col-md-4'>
                        <CardUI imgsrc={img1} title='Dindayal Upadhyay Nager (DDU)'/>
                    </div>
                    <div className='col-md-4'>
                        <CardUI imgsrc={img2} title='Telibandha (MD)' />
                    </div>
                    <div className='col-md-4'>
                        <CardUI imgsrc={img3} title='Kamal Vihar' />
                    </div>
                    <div className='col-md-4'>
                        <CardUI imgsrc={img4} title='Agroha Colony' />
                    </div>
                    <div className='col-md-4'>
                        <CardUI imgsrc={img5} title='Daganiya' />
                    </div>
                    <div className='col-md-4'>
                        <CardUI imgsrc={img6} title='Raipura'/>
                    </div>
                    <div className='col-md-4'>
                        <CardUI imgsrc={img7} title='Santoshi Nager Amlidih'/>
                    </div>
                    <div className='col-md-4' >
                        <CardUI imgsrc={img8} title='Bhathagovh'/>
                    </div>
                    <div className='col-md-4' >
                        <CardUI imgsrc={img9} title='Amleshwar'/>
                    </div>
                    <div className='col-md-4' >
                        <CardUI imgsrc={img10} title='Changorabhata' />
                    </div>
                    <div className='col-md-4' >
                        <CardUI imgsrc={img11} title='Shankar Nager'/>
                    </div>
                    <div className='col-md-4' >
                        <CardUI imgsrc={img12} title='Sarona' />
                    </div>
                
                </div>
            </div>
            </>
        )

    }
}

export default Cards

import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  // Your firebase credentials
    apiKey: "AIzaSyAXIEIYr4HfWHvwNHLQ0V9Vl42DKLO5hK8",
    authDomain: "bwarr-9be63.firebaseapp.com",
    databaseURL: "https://bwarr-9be63.firebaseio.com",
    projectId: "bwarr-9be63",
    storageBucket: "bwarr-9be63.appspot.com",
    messagingSenderId: "1065290050762",
    appId: "1:1065290050762:web:237a04a924d5f9cfcbd833",
    measurementId: "G-KKSHH24CXN"
});

const db = firebaseApp.firestore();

export { db } ;
import React from 'react';
import './App.css';
import {Element} from 'react-scroll';
import Navbar from './components/Navbar';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Courses from './components/Courses';
import {Route} from 'react-router-dom';
import {ProductConsumer} from './Context/Context';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Cards from './Cads/Cards';
import ContactUs from './ContactUs/ContactUs';


function App() {
  return (
    <ProductConsumer>
      {value=>{
        const {setClass}=value;
        return(

          <div className={setClass?"dark-mode-black":"App"}>
    

          <Navbar />     
         <Element name="Home">
         < Route exact path="/" component={Cards} />
         </Element>

         <br />
         <br />

         <div className="basic">
         <Element name="About">
         < Route exact path="/" component={About} /> 
         </Element>

         <br/><br/>

         <Element name="Skills">
         < Route exact path="/" component={Skills} />
         </Element>
          
          <br /> <br/> 
         <Element name="Projects">
         < Route exact path="/" component={Projects} />
         </Element>

         <Element name="Construction">
         < Route exact path="/" component={Courses} />
         </Element>
         </div>
         
         
        <Element name="Contact">
         < Route exact path="/" component={Contact} />
           </Element>    
         </div>
        )
      }}
    </ProductConsumer>
  );
}

export default App;

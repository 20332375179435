

// for navbar

export const Links=[

 {
     id:1,
     link:"Home",
     path:"/",
     scrolling:"Home"
 },

 {
    id:2,
    link:"About",
    path:"/about",
    scrolling:"About"
},

{
    id:3,
    link:"Our Services",
    path:"/skills",
    scrolling:"Skills"
},

{
    id:4,
    link:"Projects",
    path:"/projects",
    scrolling:"Projects"
},

{
    id:5,
    link:"Construction",
    path:"/courses",
    scrolling:"Construction"
},

{
    id:6,
    link:"Contact",
    path:"/contact",
    scrolling:"Contact"
}



]




// skills development
// options for percent:ninety,eighty,senenty,eighty,fifty,thirty
export const devSkills=[
    {
        id:1,
        title:"HTML5",
        percent:'ninety',
        number:'90%',

    },
    {   
        id:2,
        title:"CSS3",
        percent:'ninety',
        number:'90%',
    },
    {   
        id:3,
        title:"JAVASCRIPT",
       percent:'seventy',
       number:'70%'
    },
    {
        id:4,
        title:"REACT JS",
       percent:'sixty',
       number:'60%'
    },
    {
        id:5,
        title:"NODE JS",
       percent:'sixty',
       number:'60%'
    },
    {
        id:6,
        title:"EXPRESS JS",
       percent:'fifty',
       number:'50%'
    },
  
]

// skills electrical
export const electricSkills=[
    {
        id:1,
        title:"BOOTSTRAP",
        percent:'ninety',
        number:'90%',

    },
    {   
        id:2,
        title:"MATERIAL UI",
        percent:'ninety',
        number:'90%',
    },
    {   
        id:3,
        title:"FIREBASE DB",
       percent:'ninety',
       number:'90%'
    },
    {
        id:4,
        title:"MONGO DB",
       percent:'seventy',
       number:'70%'
    },
  
]

// projects
export const projects=[
    {
        id:1,
        img:"/photos/Raheja.jpg",
        title:'Raipura',
        btnTitle:"Raheja Residency",        
    },
    {   
        id:2,
        img:"/photos/Salasar.jpg",
        title:'Sarona',
        btnTitle:"Salasar Greens",
    },
    {   
        id:3,
        img:"/photos/Maruti.jpg",
        title:'Kota',
        btnTitle:"Maruti Life Style",
    },
 
  
]



// courses
export const courses=[
    {
        id:1,
        iframe:"https://www.youtube.com/embed/XP5D7vYnGnM",
        title:'Raheja Residency',
    },
    {   
        id:2,
        iframe:"https://www.youtube.com/embed/3lfU-Ehdw9Y",
        title:'Salasar Green',
    },
    {   
        id:3,
        iframe:"https://www.youtube.com/embed/MlDstzf0uXg",
        title:'Maruti Life Style',
    },
    
  
]













